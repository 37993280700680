<template>
  <div class="echartsOrder">
    <div class="case-title">
      <div class="icon_title">
        <div>
          <img class="case-title_icon" src="@/assets/images/imgAll/icon_ddtj_.png" alt />
        </div>
        <div class="case-title_max">订单统计</div>
      </div>
    </div>
    <div class="echarts">
      
      <div class="echarts_left">
        <div class="order">
          <div class="title">{{timeStr}}订单总数</div>
          <div class="number">
            询价：<span class="number-color3">{{
              inquiryOrderCount
            }}</span>
          </div>
          <div class="number">
            报价：<span class="number-color2">{{
              quoteOrderCount
            }}</span>
          </div>
          <div class="number">
            成交：<span class="number-color1">{{
              orderCount
            }}</span>
          </div>
        </div>

        <!-- <div class="order">
          <div class="title">本周订单总数</div>
          <div class="number">询价：<span class="number-color3">{{this.HomeDataList.weekInquiryOrderCount}}</span></div>
          <div class="number">报价：<span class="number-color2">{{this.HomeDataList.weekQuoteOrderCount}}</span></div>
          <div class="number">成交：<span class="number-color1">{{this.HomeDataList.weekOrderCount}}</span></div>
        </div> -->
      </div>
      <div class="echarts_right">
        <div class="btnEcharts">
          <div>

            <el-select 
            v-model="companyId" 
            clearable 
            placeholder="请选择门店" 
            filterable
            :disabled="disabled=='01'"
            style="margin:0"
            @change="handleChangeInquiry">
              <el-option
                v-for="item in InquirySelectList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </div>



          <div>
            <el-select
            v-model="merchantId"
            clearable
            placeholder="请选择回收商家"
            filterable
            style="margin:0"
            :disabled="disabled=='02'"
            @change="handleChange">
              <el-option
                v-for="item in shanpsSelectList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
              ></el-option>
            </el-select>
          </div>
          <div>
            <el-button @click="dataObtain('week')" round>过去七天</el-button>
          </div>
          <div>
            <el-button @click="dataObtain('month')" round>过去三十天</el-button>
          </div>
          <div>
            <el-date-picker
              v-model="valueTime"
              type="daterange"
              range-separator="至"
              value-format="timestamp"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
          <div>
            <el-button type="primary" @click="seachEcharts" round>查询</el-button>
          </div>
        </div>
        <div class="Polyline" id="chartLineBoxDeal"></div>
      </div>
    </div>
  </div>
</template>
<script>
import _api from "@/utils/request";
import moment from 'moment'
const echarts = require("echarts");
export default {
  name: "echartsOrder",
  props:['type','orderTotal','orderMonth','orderWeek','iiid'],
  data() {
    return {
      valueTime: "",
      startTime: "",
      endTime: "",
      dataValueX: [],
      dataValueY: [],
      dataValueY2: [],
      dataValueY3: [],
      companyId: '',
      merchantId: '',
      InquirySelectList: [],
      shanpsSelectList: [],
      disabled: '00',
      dateType: "week",
      orderCount: 0,
      quoteOrderCount: 0,
      inquiryOrderCount: 0,
      timeStr: "",
    };
  },
  created(){
  },
  mounted() {
    this.echartsData()
    this.companyList()
  },
  computed: {
    HomeDataList() {
      return this.$store.state.tagsView.HomeDataList;
    }
  },
  methods: {
    echartsData() {
      this.timeStr = this.valueTime && this.valueTime.length?moment(this.valueTime[0]).format("YYYY/MM/DD")+'-'+moment(this.valueTime[1]).format("YYYY/MM/DD"):this.dateType=='week'?"近七天":this.dateType=='month'?'过去三十天':''
      this.dataValueY=[]
      this.dataValueX=[]
      this.dataValueY2=[]
      this.dataValueY3=[]
      if(this.startTime){
        console.log(this.startTime)
      }else{
        this.endTime = Date.parse(new Date())
        this.startTime = this.endTime - 60000*60*24*7
      }
      const vo = {
        type: "01",
        startTime: this.startTime,
        endTime: this.endTime,
        merchantId: this.merchantId,
        storeId: this.companyId,
        companyId : this.iiid
      };
      _api.storechartsList(vo).then(res => {
        if (res.code === 1) {
          console.log('echartsListDeal',res)
          for(let i = 0; i < res.data.data.length; i++) {
            this.dataValueY.push(res.data.data[i].deal);
            this.dataValueY2.push(res.data.data[i].inquiry);
            this.dataValueY3.push(res.data.data[i].quote);
            this.dataValueX.push(res.data.data[i].orderTime);
          }
          this.orderCount = res.data.orderCount || 0
          this.quoteOrderCount = res.data.quoteOrderCount || 0
          this.inquiryOrderCount = res.data.inquiryOrderCount || 0
           this.$nextTick(()=>{
            myEcharts.setOption(option,true)
          })
        }
      });
      var myEcharts = echarts.init(document.getElementById("chartLineBoxDeal"));
      let seriesArr = []
      if(this.companyId){
        seriesArr=[
          {
            name: "成交单",
            type: "line",
            smooth: true,
            data: this.dataValueY,
            color: '#5470c6',
            areaStyle:{color:'rgba(9, 129, 255, 0.06)'}
          },
          {
            name: "询价单",
            type: "line",
            color: '#fac858',
            smooth: true,
            data: this.dataValueY2,
            areaStyle:{color:'rgba(9, 129, 255, 0.06)'}
          }
        ]
      }else if(this.merchantId){
        seriesArr = [
          {
            name: "成交单",
            type: "line",
            color: '#5470c6',
            smooth: true,
            data: this.dataValueY,
            areaStyle:{color:'rgba(9, 129, 255, 0.06)'},
            // itemStyle:{
            //   normal:{
            //     color:'#fff'
            //   },
            // }
          },
          {
            name: "报价单",
            type: "line",
            color: '#91cc75',
            smooth: true,
            data: this.dataValueY3,
            areaStyle:{color:'rgba(95, 201, 58, 0.06)'},
            // itemStyle:{
            //   normal:{
            //     color:'#fff'
            //   },
            // }
          }
        ]
      }else{
        seriesArr = [
          {
            name: "成交单",
            type: "line",
            color: '#5470c6',
            smooth: true,
            data: this.dataValueY,
            areaStyle:{color:'rgba(9, 129, 255,0.06)'},
            // itemStyle:{
            //   normal:{
            //     color:'rgba(9, 129, 255, 0.06)'
            //   },
            // }
          },
          {
            name: "报价单",
            type: "line",
            color: '#91cc75',
            smooth: true,
            data: this.dataValueY3,
            areaStyle:{color:'rgba(95, 201, 58, 0.06)'},
            // itemStyle:{
            //   normal:{
            //     color:'rgba(95, 201, 58, 0.06)'
            //   },
            // }
          },
          {
            name: "询价单",
            type: "line",
            color: '#fac858',
            smooth: true,
            data: this.dataValueY2,
            areaStyle:{color:'rgba(255, 175, 41, 0.06)'},
            // itemStyle:{
            //   normal:{
            //     color:'rgba(255, 175, 41, 0.06)'
            //   },
            // }
          }
        ]
      }
      const option = {
        tooltip: {
          trigger: "axis",
          textStyle:{
            color:'white'
          },
          backgroundColor:"rgba(9, 129, 255, 0.8)",
          extraCssText:'border-radius:10px'
          
        },
        legend: {
          data: ['报价单','询价单', '成交单'],
          right: '135',
          top:'20'
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dataValueX
        },
        yAxis: {
          name: this.valueTime && this.valueTime.length?moment(this.valueTime[0]).format("YYYY/MM/DD")+'-'+moment(this.valueTime[1]).format("YYYY/MM/DD")+'订单统计':this.dateType=='week'?"近七天订单统计":this.dateType=='month'?'过去三十天订单统计':'',
          type: "value"
        },
        series: seriesArr
      }
      console.log(option);
      myEcharts.setOption(option,true);

      
     
    },
    seachEcharts() {
      if (this.valueTime !== null && this.valueTime) {
        (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        (this.startTime = ""), (this.endTime = "");
      }
      console.log(this.startTime)
      console.log(this.endTime)

      this.echartsData();
    },
    //点击事件
    dataObtain(way) {
      this.endTime = Date.parse(new Date())
      if(this.dateType == way){
        return
      }
      this.dateType = way
      if(way==='week'){
        this.startTime =  this.endTime - 60000*60*24*7
        this.echartsData()
      } else{
        this.startTime = this.endTime - 60000*60*24*30
        this.echartsData()
      }
    },
    handleChange(val) {
      this.merchantId = val
      this.companyId = ""
      this.disabled = '01'
      if(!val){
        this.disabled = '00'
      }
      this.echartsData()
      this.$forceUpdate()
      console.log(this.merchantId)
    },
    handleChangeInquiry(val) {
      console.log(val)
      this.companyId = val
      this.disabled = '02'
      this.merchantId = ""
      if(!val){
        this.disabled = '00'
      }
      this.echartsData()
      this.$forceUpdate()
    },
    companyList(){
      const params = {
        companyId:this.iiid
      }
      _api.getStoreSelectList(params).then((res) => {
        if (res.code === 1) {
          this.InquirySelectList = res.data
        }
      })
      _api.getMiddleAdoptMerchant(params).then((res) => {
        if (res.code === 1) {
          this.shanpsSelectList = res.data
        }
      })
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.echartsOrder {
  width: 100%;
  height: 660px;
  background-color: white;
  .el-button.is-round {
    padding: 10px 33px !important;
  }
  .case-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #f6f6f6;
    .icon_title {
      display: flex;
      .case-title_icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .case-title_max {
        display: inline-block;
        width: 100px;
        height: 17px;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
  .echarts {
    width: 100%;
    height: 560px;
    display: flex;
    &_left {
      width: 15%;
      .order {
        margin-top: 50px;
        width: 250px;
        height: 100px;
        text-align: center;
        .title {
          margin: 0 auto;
          width: 162px;
          font-size: 14px;
          font-family: FZLanTingHei-M-GBK;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
        }
        .number {
          margin: 0 auto;
          width: 100px;
          font-size: 14px;
          font-family: FZLanTingHei-M-GBK;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          .number-color1{
            color:#5470c6;
            font-weight: bold;
          }
          .number-color2{
            color: #91cc75;
            font-weight: bold;
          }
          .number-color3{
            color: #fac858;
            font-weight: bold;
          }
        }
      }
    }
    &_right {
      width: 85%;
      .btnEcharts {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          margin: 20px;
        }
      }
      .Polyline {
        width: 100%;
        height: 560px;
      }
    }
  }
}
</style>
