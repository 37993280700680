<template>
  <div class="Storetable">
    <div style="display: flex; position: relative">
      <div
        class="btn"
        :class="this.orderType == '01' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('01')"  
      >
        累计报价排行
      </div>
      <div
        class="btn"
        :class="orderType == '02' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('02')"
      >
        累计成交排行
      </div>
      <div
        class="btn"
        :class="orderType == '03' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('03')"
      >
        毛利贡献排行
      </div>
      <!-- <div
        class="btn btnType1"
        @click="exportExcel"
        style="
          padding: 0 20px;
          position: absolute;
          right: 0;
          width: unset;
          margin-right: 5px;
        "
      >
        表格导出
      </div> -->
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        height: 32px;
        margin: 16px 0 20px 0;
      "
    >
      <div style="margin-right:20px">
        <el-select
          v-model="partitionval"
          placeholder="请选择总监/区域"
          clearable
          size="small"
        >
          <el-option
            v-for="item in partition"
            :key="item.name"
            :value="item.staffId"
            :label="
              item.areaName
                ? `${item.staffName}-${item.areaName}`
                : item.staffName
            "
          >
          </el-option>
        </el-select>
      </div>
      <div style="display: flex">
        <el-button
          :type="dateType == '01' ? 'primary' : 'default'"
          size="small"
          @click="ClickDateType('01')"
          >今日</el-button
        >
        <el-button
          :type="dateType == '03' ? 'primary' : 'default'"
          size="small"
          @click="ClickDateType('03')"
          >本周</el-button
        >
        <el-button
          :type="dateType == '05' ? 'primary' : 'default'"
          size="small"
          @click="ClickDateType('05')"
          >本月</el-button
        >
      </div>
      <div class="el-date-time" style="margin-right: 0">
        <el-date-picker
          style="flex: 1"
          v-model="valueTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          size="small"
          @change="timeClick"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <!-- <span class="add_time" @click="addTimeChoose">{{addTimeText}}</span>
      <div  v-if="addTimeTrue" class="el-date-time" style="margin-right: 0;display:flex;align-items: center;">
          <el-date-picker
            v-model="thanValueTime"
            :picker-options="pickerOptions"
            size="small"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            @change="thanValueTimeChange"
           
            >
          </el-date-picker>
          <span @click="addTimeCancle" v-if="addTimeTrue == true"><img src="../../../../assets/images/home/-s-zy_ico_close.png" class="close_img"></span>
        </div> -->
      <div>
        <div class="btnType3" @click="handleCurrentChange">
          <i class="el-icon-search"></i>查询
        </div>
      </div>
      <el-button type="text" style="margin-left: 20px" @click="ClickDiaRec"
        >回收商家报价分析</el-button
      >
    </div>
    <el-table
      max-height="450"
      :data="tableData"
      border
      style="width: 100%"
      v-loading="storetableLoading"
    >
      <template slot="empty">
        <div class="table_empty">
          <img
            src="../../../../assets/images/tjsjtb.png"
            style="width:200px;height:100px"
            alt=""
          />
          <span style="font-size:12px;line-height:12px;padding-left:12px"
            >暂无数据</span
          >
        </div>
      </template>
      <el-table-column label="排行" type="index" align="center" width="70">
        <template slot-scope="{ $index }">
          <div class="numBG_1" v-if="$index === 0">1</div>
          <div class="numBG_2" v-else-if="$index === 1">2</div>
          <div class="numBG_3" v-else-if="$index === 2">3</div>
          <div v-else>{{ $index + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column label="回收商" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.name"
            placement="top"
          >
            <div class="Remarks">
              <span>{{ scope.row.name }}</span>
            </div>
          </el-tooltip>
          <div class="isdeal1" v-if="scope.row.isOutMerchant">外部</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="quote"
        label="累计报价量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="normalQuoteNum"
        label="有效报价量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="deal"
        label="累计成交量"
        align="center"
      ></el-table-column>
      <el-table-column label="成交率" align="center">
        <template slot-scope="{ row }"> {{ row.dealRate }}% </template>
      </el-table-column>
      <el-table-column
        prop="dealAmount"
        label="累计交易额"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="unitPrice"
        label="客单价"
        :render-header="renderHeader"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="grossProfit"
        label="毛利贡献"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="renewSubsidyPriceAll"
        label="换新补贴"
        align="center"
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
export default {
  name: "Storetable",
  props: ["partition","iid"],
  data() {
    return {
      addTimeText: '+添加对比时间',
      addTimeTrue: false,
      isThan: false,
      isThanTrue: true,
      thanValueTime: [],
      storetableLoading: false,
      valueTime: "",
      orderType: "01",
      dateType: "01",
      tableData: [],
      partitionval: "",
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      staffType:"",
    };
  },
  created() {
    this.handleCurrentChange();
  },
  methods: {
    addTimeChoose () {
      this.addTimeText = '对比时间'
      this.addTimeTrue = true
      this.isThan = true
      this.isThanTrue = true
    },
    addTimeCancle () {
      this.addTimeText = '+添加对比时间'
      this.addTimeTrue = false
      this.isThan = false
      this.isThanTrue = true
      this.dateTime = []
      this.thanValueTime = []
      this.dateOn = '01'
      // this.getHomeData()
    },
    ClickDiaRec() {
      this.$emit("ClickDiaRec");
    },
    renderHeader(h) {
      return (
        <div style="display:flex;justify-content: center;">
          <span>客单价</span>
          <el-tooltip
            class="item"
            effect="dark"
            content="累计交易额/累计成交量"
            placement="right"
          >
            <i
              style="display: flex;flex-direction: row;align-items: center;justify-content: center;margin-left: 4px;"
              class="el-icon-warning-outline"
            ></i>
          </el-tooltip>
        </div>
      );
    },
    // 表格切换页面
    handleCurrentChange() {
      const baseRequest = {
        companyId: this.iid,
        dateType: this.dateType, //	今日 01 本周：03，本月：05
        endTime: this.valueTime ? this.valueTime[1] : "",
        orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
        queryType: "02", //查询类别 01 门店商家订单及交易统计：02，各回收商家交易数据统计
        startTime: this.valueTime ? this.valueTime[0] : "",
        xcxStaffId: this.partitionval,
        // thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
        // thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
      };
      this.storetableLoading = true;
      _api.getMerchantStatisticsRank(baseRequest).then((res) => {
        console.log(res, "HomeOrderList");
        if (res.code === 1) {
          this.tableData = res.data;
        }
        this.storetableLoading = false;
      });
    },
    exportExcel() {
      _api
        .getexcelMerchantStatisticsRank({
          companyId: this.iid,
          dateType: this.dateType, //	今日 01 本周：03，本月：05
          endTime: this.valueTime ? this.valueTime[1] : "",
          orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
          queryType: "02", //查询类别 01 门店商家订单及交易统计：02，各回收商家交易数据统计
          startTime: this.valueTime ? this.valueTime[0] : "",
          xcxStaffId: this.partitionval,
        })
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);

          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "各回收商家交易数据统计.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ClickOrderType(val) {
      this.orderType = val;
      console.log(this.orderType);
      this.$emit("ClickOrderType", val);
      this.handleCurrentChange();
    },
    ClickDateType(val) {
      this.valueTime = "";
      this.dateType = val;
      this.handleCurrentChange();
    },
    timeClick(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">

.Storetable {
  .add_time {
    color: #20A0FF;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    cursor: pointer;
  }
  .close_img {
    width: 16px;
    margin-left: 8px;
    margin-top: 2px;
  }
  .el-button--default {
    //需要更改的按钮类型 type='default'
    background: #f9fbfd !important;
    border-color: #d9e6ff !important;
    color: #8492a6 !important;
  }
  //移入时按钮样式type='default'
  .el-button--default:hover {
    background: #fff !important;
    border-color: #45a3fc !important;
    color: #20a0ff !important;
    opacity: 0.8;
  }
  .el-button--primary {
    //需要更改的按钮类型 type='primary'
    background: #20a0ff !important;
    border-color: #20a0ff !important;
  }
  //移入时按钮样式 type='primary'
  .el-button--primary:hover {
    background: #20a0ff !important;
    border-color: #20a0ff !important;
    color: #fff !important;
    opacity: 0.8;
  }
  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }
  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }
  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }
  // /deep/ .el-input__inner {
  //   width: 306px !important;
  // }
  /deep/ .el-range-separator {
    width: 10% !important;
  }
  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    color: #03b6f9;
    cursor: pointer;
  }
  .btnType1 {
    background: #0981ff;
    color: white;
    height: 32px;
    background: #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    padding: 0 9px;
  }
  .btnType-after1 {
    padding: 0 9px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #20a0ff;
    line-height: 32px;
  }
  .btnType2 {
    // width: 100px;
    min-width: 30px;
    flex: 0.3;
    height: 36px;
    background: rgba(3, 182, 249, 1);
    color: white;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType-after2 {
    // width: 100px;
    min-width: 30px;
    flex: 0.3;
    height: 36px;
    background: #ffffff;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType3 {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    text-align: center;
    cursor: pointer;
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #20a0ff;
    color: white;
    border-radius: 4px;
    margin-left: 20px;
    font-size: 14px;
  }
  .phoneTable {
    .orderPagina {
      float: right;
      padding: 30px;
    }
  }
}
.el-date-time {
  // /deep/ .el-input__inner {
    width: 300px !important;
  // }
  margin: 0 20px;
  /deep/ .el-range-separator {
    width: 10% !important;
  }
}
.Remarks {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; //控制行数
  overflow: hidden;
}
.table_empty {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.equationBlue {
    color: #20A0FF;
  }
  .equationGreen {
    color: #49A915;
  }
  .equationRed {
    color: #FF4949;
  }
.isdeal1 {
  border:1px solid #f59a23;
  display: inline-block;
  width: max-content;
  padding: 0px 4px;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 5px;
  color: #f59a23;
  text-align: center;
  margin-left: 5px;
}
</style>
