<template>
  <el-dialog title="新机指标分析" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <el-form>
      <div>选择时间：<el-link type="primary" :underline="false">{{showTime}}</el-link></div>
      <el-form-item label="请选择需要导出的指标："></el-form-item>
      <div v-for="item in indicatorList" :key="item.label" style="padding-left: 20px; margin-bottom: 5px">
        <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checked" @change="(e) => handleCheckAllChange(item, e)" style="margin-bottom:10px">{{item.label}}</el-checkbox>
        <div style="padding-left: 20px">
          <el-checkbox v-for="it in item.children" :key="it.label" v-model="it.checked" @change="handleItemChange(item)" style="margin-bottom:10px">{{it.label}}</el-checkbox>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click.native="visible = false">取消</el-button>
      <el-button type="primary" @click.native="confirm">确定导出</el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import _api from "@/utils/request";
import { guideExcelAction } from "@/utils/common";
const indicatorList = [
  {
    label: '新机指标',
    checked: false,
    isIndeterminate: false,
    children: [
      { label: '新机销量', checked: false },
      { label: '询价率', checked: false },
      { label: '跟机率', checked: false },
      { label: '单客贡献', checked: false },
    ]
  },
  {
    label: '任务指标',
    checked: false,
    isIndeterminate: false,
    children: [
      { label: '基础利润目标', checked: false },
      { label: '挑战利润目标', checked: false },
      { label: '基础目标差额', checked: false },
      { label: '挑战目标差额', checked: false },
      { label: '基础目标完成率', checked: false },
      { label: '挑战目标完成率', checked: false },
    ]
  },
]
export default {
  name: "NewMachineAnalysisDia",
  data() {
    return {
      visible: false,
      params: {},
      dateTypeOptions: [
        { label: '今日', value: '01' },
        { label: '昨日', value: '02' },
        { label: '本周', value: '03' },
        { label: '本月', value: '05' },
      ],
      indicatorList: [],
    };
  },
  computed: {
    showTime() {
      const { dateType, startTime, endTime } = this.params
      if (startTime && endTime) return [moment(startTime).format('YYYY.MM.DD'), moment(endTime).format('YYYY.MM.DD')].join('~')
      return this.dateTypeOptions.find(item => item.value == dateType)?.label
    }
  },
  methods: {
    open(params) {
      this.params = params
      this.indicatorList = JSON.parse(JSON.stringify(indicatorList))
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleCheckAllChange(item, e) {
      item.children.forEach(c => c.checked = e)
      item.isIndeterminate = false
    },
    handleItemChange(item) {
      const trueIndex = item.children.findIndex(c => c.checked)
      const falseIndex = item.children.findIndex(c => !c.checked)
      if (trueIndex == -1 || falseIndex == -1) {
        item.checked = falseIndex == -1 ? true : false
        item.isIndeterminate = false
      } else {
        item.checked = false
        item.isIndeterminate = true
      }
    },
    confirm() {
      const dynamicFields = this.indicatorList.reduce((prev, indicator) => {
        indicator.children.forEach(item => {
          if (item.checked) prev.push(item.label)
        })
        return prev
      }, [])
      // if (!dynamicFields)
      _api.excelStoreNewMachineAnalysis({
        ...this.params,
        dynamicFields
      }).then(r => {
        guideExcelAction(r, `【门店新机指标】${this.showTime}`, () => {
          // this.excelLoading = false;
          this.$emit('confirm')
          this.visible = false
        })
      })
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-form-item {
  margin-bottom: 0;
}
</style>